/* eslint-disable indent */
import React, { useCallback, useMemo } from "react";
import screenmediaLogo from "../../img/screenmedia-logo.png";
import visionaLogo from "../../img/visiona-logo.png";
import wecastLogo from "../../img/wecast-logo.png";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import styles from "./styles"; // Importa los estilos desde el archivo separado

const today = new Date();
const month = String(today.getMonth() + 1).padStart(2, "0");
const day = String(today.getDate()).padStart(2, "0");
const date = `${day}-${month}-${today.getFullYear()}`;
const hour = String(today.getHours()).padStart(2, "0");
const minute = String(today.getMinutes()).padStart(2, "0");

const PdfCotizacion = ({
  ascensoresSeleccionados = 0,
  audienciaSeleccionadaAscensor = 0,
  audienciaSeleccionadaTotal = 0,
  audienciaSeleccionadaTotems = 0,
  codigoGenerado,
  cpmAscensoresFinal = 0,
  cpmTotalFinal = 0,
  cpmTotemsFinal = 0,
  descuentoTotalAscensores = 0,
  descuentoTotalFinal = 0,
  descuentoTotalTotems = 0,
  duracion = 0,
  edificiosSeleccionadosAscensores = 0,
  edificiosSeleccionadosTotems = 0,
  edificiosUnicosTotal = 0,
  horas,
  horasFinal = 0,
  impactosTotalAscensores = 0,
  impactosTotalTotems = 0,
  impactosTotales = 0,
  intervalos,
  inversionAscensoresFinal = 0,
  inversionFinalTotal = 0,
  inversionTotemsFinal = 0,
  isProgrammaticSelected,
  nombresEdificiosNoSeleccionados = [],
  nombresEdificiosSeleccionadosAscensores = [],
  nombresEdificiosSeleccionadosTotems = [],
  options,
  pais = "Chile",
  plazo = 0,
  salidasAscensores = 0,
  salidasTotales = 0,
  salidasTotems = 0,
  tarifaFriaAscensores = 0,
  tarifaFriaTotal = 0,
  tarifaFriaTotems = 0,
  totemsSeleccionados = 0,
  usuarioApellido,
  usuarioNombre,
}) => {
  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const monedaSimbolo = useCallback(
    (pais) =>
      ({
        Chile: "CLP ",
        Perú: "PEN ",
        Uruguay: "URU ",
      })[pais] || "CLP ",
    []
  );

  const ciudad = useCallback(
    (pais) =>
      ({
        Chile: "Santiago",
        Perú: "Lima",
        Uruguay: "Montevideo",
      })[pais] || "Lima",
    []
  );

  const operacionTextual = useCallback(
    (pais) =>
      ({
        Chile: "WECAST",
        Perú: "VISIONA",
        Uruguay: "SCREENMEDIA",
      })[pais] || "VISIONA",
    []
  );

  const monedaTextual = useCallback(
    (pais) =>
      ({
        Chile: "CLP",
        Perú: "PEN",
        Uruguay: "URU",
      })[pais] || "CLP",
    []
  );

  const mailTextual = useCallback(
    (pais) =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: wecastLogo,
        Perú: visionaLogo,
        Uruguay: screenmediaLogo,
      })[pais] || visionaLogo,
    [pais]
  );

  // Calcular totales
  const totalPantallas = ascensoresSeleccionados + totemsSeleccionados;
  const nombreUsuario = `${usuarioNombre} ${usuarioApellido}`;

  const ordenarIntervalos = (a, b) => {
    const [horaA, minutoA] = a.split(" - ")[0].split(":").map(Number);
    const [horaB, minutoB] = b.split(" - ")[0].split(":").map(Number);

    if (horaA !== horaB) {
      return horaA - horaB;
    }
    return minutoA - minutoB;
  };

  // Función para manejar singular, plural y casos con 0
  const pluralizar = (cantidad, singular, plural) => {
    if (cantidad === 0) return "ningún edificio";
    return cantidad === 1 ? singular : plural;
  };

  return (
    <Document>
      <Page size={[595, 890]} orientation="landscape" style={styles.page}>
        <Text style={styles.header} fixed>
          ~ CONFIDENCIAL ~
        </Text>
        <Text style={styles.title}>COTIZACIÓN de ESPACIOS PUBLICITARIOS en {operacionTextual(pais)}</Text>
        <Text style={styles.letrachica0}>
          {ciudad(pais)}, {date} @ {hour}:{minute} | Generado por: {nombreUsuario} | Cotización ID: {codigoGenerado}
        </Text>

        {/* Tabla con estilos Bootstrap-like */}
        <View style={styles.table}>
          <View
            style={[
              styles.tableRow,
              styles.tableHeaderTipo,
              {
                backgroundColor: isProgrammaticSelected ? "#006400" : "#FF8C00", // Verde oscuro para programmatic, naranja oscuro para digital
              },
            ]}
          >
            <Text>TIPO DE COMPRA: {isProgrammaticSelected ? "PROGRAMMATIC" : "DIGITAL"}</Text>
          </View>

          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.tableCellStart}>CIRCUITO</Text>
            {/* <Text style={styles.tableCell}>EDIFICIOS</Text> */}
            <Text style={styles.tableCell}>PANTALLAS</Text>
            <Text style={styles.tableCell}>AUDIENCIA</Text>
            <Text style={styles.tableCellSmall}>HORAS ON</Text>
            <Text style={styles.tableCellSmall}>PLAZO</Text>
            <Text style={styles.tableCellQuote}>IMPRESIONES</Text>
            <Text style={styles.tableCellQuote}>IMPACTOS</Text>
            <Text style={styles.tableCell}>DURACIÓN</Text>
            <Text style={styles.tableCellQuote}>COTIZACIÓN</Text>
            {!isProgrammaticSelected && <Text style={[styles.tableCell, styles.tableHeaderRed]}>DESCUENTO</Text>}
            <Text style={[styles.tableCellQuote, styles.tableHeaderGreen]}>INVERSIÓN</Text>
            <Text style={[styles.tableCell, styles.tableHeaderBlue, styles.lastCell]}>CPM</Text>
          </View>

          {/* Datos de Ascensores */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellStart, styles.tableData]}>ASCENSORES</Text>
            {/* <Text style={[styles.tableCell, styles.tableData]}>
              {ascensoresSeleccionados ? edificiosSeleccionadosAscensores : "-"}
            </Text> */}
            <Text style={[styles.tableCell, styles.tableData]}>
              {ascensoresSeleccionados ? ascensoresSeleccionados : "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableData]}>
              {ascensoresSeleccionados
                ? audienciaSeleccionadaAscensor.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCellSmall, styles.tableData]}>{ascensoresSeleccionados ? horasFinal : "-"}</Text>
            <Text style={[styles.tableCellSmall, styles.tableData]}>{ascensoresSeleccionados ? plazo : "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {ascensoresSeleccionados
                ? salidasAscensores.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {ascensoresSeleccionados
                ? impactosTotalAscensores.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableData]}>{ascensoresSeleccionados ? duracion : "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {ascensoresSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    tarifaFriaAscensores
                      ? tarifaFriaAscensores.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
            {!isProgrammaticSelected && (
              <Text style={[styles.tableCell, styles.tableData]}>
                {ascensoresSeleccionados
                  ? `${(descuentoTotalAscensores * 100).toLocaleString(localString(pais), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}%`
                  : "-"}
              </Text>
            )}
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {ascensoresSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    inversionAscensoresFinal
                      ? inversionAscensoresFinal.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
              {ascensoresSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    cpmAscensoresFinal
                      ? cpmAscensoresFinal.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
          </View>

          {/* Datos de Tótems */}
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellStart, styles.tableData]}>TÓTEMS</Text>
            {/* <Text style={[styles.tableCell, styles.tableData]}>
              {totemsSeleccionados ? edificiosSeleccionadosTotems : "-"}
            </Text> */}
            <Text style={[styles.tableCell, styles.tableData]}>{totemsSeleccionados ? totemsSeleccionados : "-"}</Text>
            <Text style={[styles.tableCell, styles.tableData]}>
              {totemsSeleccionados
                ? audienciaSeleccionadaTotems.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCellSmall, styles.tableData]}>{totemsSeleccionados ? horasFinal : "-"}</Text>
            <Text style={[styles.tableCellSmall, styles.tableData]}>{totemsSeleccionados ? plazo : "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {totemsSeleccionados
                ? salidasTotems.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {totemsSeleccionados
                ? impactosTotalTotems.toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableData]}>{totemsSeleccionados ? duracion : "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {totemsSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    tarifaFriaTotems
                      ? tarifaFriaTotems.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
            {!isProgrammaticSelected && (
              <Text style={[styles.tableCell, styles.tableData]}>
                {totemsSeleccionados
                  ? `${(descuentoTotalTotems * 100).toLocaleString(localString(pais), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}%`
                  : "-"}
              </Text>
            )}
            <Text style={[styles.tableCellQuote, styles.tableData]}>
              {totemsSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    inversionTotemsFinal
                      ? inversionTotemsFinal.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableData, styles.lastCell]}>
              {totemsSeleccionados
                ? `${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                    cpmTotemsFinal
                      ? cpmTotemsFinal.toLocaleString(localString(pais), {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      : "-"
                  }`
                : "-"}
            </Text>
          </View>

          {/* Totales */}
          <View style={[styles.tableRow, styles.tableTotal]}>
            <Text style={[styles.tableCellStart, styles.tableDataTotales]}>TOTALES</Text>
            {/* <Text style={[styles.tableCell, styles.tableDataTotales]}>{edificiosUnicosTotal || "-"}</Text> */}
            <Text style={[styles.tableCell, styles.tableDataTotales]}>{totalPantallas || "-"}</Text>
            <Text style={[styles.tableCell, styles.tableDataTotales]}>
              {audienciaSeleccionadaTotal.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) || "-"}
            </Text>
            <Text style={[styles.tableCellSmall, styles.tableDataTotales]}>{horasFinal || "-"}</Text>
            <Text style={[styles.tableCellSmall, styles.tableDataTotales]}>{plazo || "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
              {salidasTotales.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) || "-"}
            </Text>
            <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
              {impactosTotales.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }) || "-"}
            </Text>
            <Text style={[styles.tableCell, styles.tableDataTotales]}>{duracion || "-"}</Text>
            <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
              {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                tarifaFriaTotal
                  ? tarifaFriaTotal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"
              }`}
            </Text>
            {!isProgrammaticSelected && (
              <Text style={[styles.tableCell, styles.tableDataTotales]}>
                {descuentoTotalFinal
                  ? `${(descuentoTotalFinal * 100).toLocaleString(localString(pais), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    })}%`
                  : "-"}
              </Text>
            )}
            <Text style={[styles.tableCellQuote, styles.tableDataTotales]}>
              {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                inversionFinalTotal
                  ? inversionFinalTotal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"
              }`}
            </Text>
            <Text style={[styles.tableCell, styles.tableDataTotales, styles.lastCell]}>
              {`${isProgrammaticSelected ? "US$" : monedaSimbolo(pais)} ${
                cpmTotalFinal
                  ? cpmTotalFinal.toLocaleString(localString(pais), {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : "-"
              }`}
            </Text>
          </View>
        </View>
        <View style={styles.hr} />
        <View style={styles.horario}>
          <Text style={styles.horarioTitulo}>HORARIO:</Text>
          {isProgrammaticSelected ? (
            <Text style={styles.horariotext}>
              Intervalos:{" "}
              {intervalos.length > 0
                ? intervalos.sort(ordenarIntervalos).join(" | ")
                : "No hay intervalos seleccionados"}
            </Text>
          ) : (
            <Text style={styles.horariotext}>
              {options.length > 0 ? options[0].label : "No hay opciones seleccionadas"}
            </Text>
          )}
        </View>
        <View style={styles.inventario}>
          <Text style={styles.inventarioTitulo}>INVENTARIO:</Text>
          {/* Circuito Ascensores */}
          <Text style={styles.inventariosubTitulo}>
            • Circuito Ascensores,{" "}
            {nombresEdificiosSeleccionadosAscensores.length > 0
              ? `${nombresEdificiosSeleccionadosAscensores.length} ${pluralizar(nombresEdificiosSeleccionadosAscensores.length, "edificio:", "edificios:")}`
              : "ningún edificio seleccionado"}
          </Text>
          {nombresEdificiosSeleccionadosAscensores.length > 0 && (
            <Text style={styles.inventarioNombres}>{nombresEdificiosSeleccionadosAscensores.join(", ")}</Text>
          )}

          {pais !== "Perú" && (
            <>
              {/* Circuito Circulación */}
              <Text style={styles.inventariosubTitulo}>
                • Circuito Circulación,{" "}
                {nombresEdificiosSeleccionadosTotems.length > 0
                  ? `${nombresEdificiosSeleccionadosTotems.length} ${pluralizar(nombresEdificiosSeleccionadosTotems.length, "edificio:", "edificios:")}`
                  : "ningún edificio seleccionado"}
              </Text>
              {nombresEdificiosSeleccionadosTotems.length > 0 && (
                <Text style={styles.inventarioNombres}>{nombresEdificiosSeleccionadosTotems.join(", ")}</Text>
              )}
            </>
          )}
          {/* Edificios No Seleccionados */}
          <Text style={styles.inventariosubTitulo}>• Edificios no seleccionados:</Text>
          {nombresEdificiosNoSeleccionados && nombresEdificiosNoSeleccionados.length > 0 ? (
            <Text style={styles.inventarioNombresLast}>{nombresEdificiosNoSeleccionados.join(", ")}</Text>
          ) : (
            <Text style={styles.inventarioNombresLast}>Todos los edificios fueron seleccionados :)</Text>
          )}
        </View>
        <View style={styles.hr} />

        <View>
          <Text style={styles.letrachica2}>
            Propuesta exclusiva para agencia y/o cliente cotizado. Importes expresados en{" "}
            {isProgrammaticSelected ? "US$" : `${monedaTextual(pais)}`} y no incluyen impuestos. Exhibición de lunes a
            viernes de 7 AM a 9 PM según frecuencia y horario contratado. Propuesta incluye descuentos especiales que no
            podrán considerarse como antecedente. El cliente podrá cambiar materiales tantas veces como desee.
            Cotización válida para pautas a emitirse hasta diciembre 2024 inclusive.
          </Text>
          <Image style={styles.image} src={logo} />
          <Text style={styles.mail}>{mailTextual(pais)}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfCotizacion;
